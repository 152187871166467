import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css'],
  providers: [GlobalService]
})
export class RoleComponent implements OnInit {

	referred:string;

	constructor(
		private global: GlobalService,
		private _route: ActivatedRoute
	) { 

		this._route.paramMap.subscribe((params: ParamMap) => {
			this.referred = params.get('id');
			console.log(this.referred);
		});
	}

	ngOnInit() {
	}

}
