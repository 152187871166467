import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home/home.component';
import { DetailComponent } from './detail/detail.component';
import { NewsComponent } from './news/news.component';
import { AboutComponent } from './about/about.component';
import { CoreRoutingModule } from './core-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { ProfileComponent } from '../operation/profile/profile.component';
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';
import { SharedModule } from '../shared/shared.module';
import { RoleComponent } from './role/role.component';
import { FormsModule  } from "@angular/forms"; 
import { HttpClientModule } from '@angular/common/http';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { FaqComponent } from './faq/faq.component';
import { PoliticsComponent } from './politics/politics.component';
import { RecommendsComponent } from './recommends/recommends.component';
import { TermsComponent } from './terms/terms.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { SafePipe } from './../safe.pipe';
import { AssociationsComponent } from './associations/associations.component';

@NgModule({
  declarations: [
    HomeComponent, 
 	  DetailComponent, 
 	  NewsComponent, 
   	AboutComponent, 
   	LoginComponent, 
   	RoleComponent, 
   	RegisterComponent, 
   	PathNotFoundComponent, 
   	TermsAndConditionsComponent, 
   	FaqComponent, 
   	PoliticsComponent, 
    RecommendsComponent, 
    TermsComponent, 
    DocumentationComponent,
    SafePipe,
    AssociationsComponent
 ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    NgbModule,
    SharedModule,
    FormsModule,
    HttpClientModule
  ]
})
export class CoreModule { }
