import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from './shared/shared.module';
import { HttpClient,HttpClientModule } from '@angular/common/http';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule  } from "@angular/forms"; 
import { Router } from '@angular/router';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { GlobalService } from './services/global.service';
import { LiquidationComponent } from './main/routes/liquidation/liquidation.component';
//import { SafePipe } from './safe.pipe';
// import { DataFilterPipe } from './data-filter.pipe';
// import { IveIndividualComponent } from './app/operation/ive-individual/ive-individual.component';

// import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
// import {TranslateHttpLoader} from '@ngx-translate/http-loader';

// import { AngularFireModule } from '@angular/fire';

// export function HttpLoaderFactory(http: HttpClient) {
    // return new TranslateHttpLoader(http);
    // return new TranslateHttpLoader(http, './assets/i18n/', '.json');
// }

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LiquidationComponent,
    //SafePipe,
    // DataFilterPipe,
    // IveIndividualComponent,
    // NewAccountComponent
  ],
  imports: [
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    NgbModule,
    SharedModule,
    FormsModule,
    // TranslateModule.forChild({
    //     loader: {
    //         provide: TranslateLoader,
    //         useFactory: HttpLoaderFactory,
    //         deps: [HttpClient]
    //     }
    // })
    // ReactiveFormsModule,
    // ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  entryComponents:[

  ],
  exports: [
    // NewAccountComponent, 
  ]
})
export class AppModule {}
