import { Component, OnInit, ViewChild,ElementRef  } from '@angular/core';
import { NgbTabsetConfig,NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { GlobalService } from '../../services/global.service';
import { HttpClient } from '@angular/common/http';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';  

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbTabsetConfig]
})
export class HomeComponent implements OnInit {

  is_login:any = false;
  data:any = {l_value_currency:(0).toFixed(2)};
  exchange_rate:any = {};
  closeResult:any;

  buy_alert:any = (0).toFixed(2);
  sell_alert:any = (0).toFixed(2);

  type:number = 0;
  first_currency:any = "Qtz";
  last_currency:any = "Dol";
  f_simbol_c:any = "Q";
  l_simbol_c:any = "$";
  f_value_currency:any = "0.00";
  show_accounts:boolean = false;
  saving:any = 0;
  user:any;
  res:any;
  anuncio_video:boolean = false;
  anuncio_image:boolean = true;
  url:string;
  ad_title:string;
  ad_description:string;

  @ViewChild('publicity', {static: false}) publicity: ElementRef;

  constructor(
    config: NgbTabsetConfig,
    public global:GlobalService,
    private http: HttpClient,
    private modalService: NgbModal,
    private router: Router,
    public translate: TranslateService
   ) {

    // customize default values of tabsets used by this component tree
    config.justify = 'center';
    config.type = 'pills';

    this.data.type = 'sell';
    this.global.is_login().then((res)=>{
      this.is_login = res;
    });

    var headers = this.global.get_headers();
    this.http.get(this.global.api_url()+'ads',headers).subscribe((data) => {

      this.ad_title = data['titulo'];
      this.ad_description = data['description'];

      var arrData = data['url'].split('/');
      this.url = this.global.admin_url() + '/storage/' + arrData[1];
      var type = arrData[1].split('.')[1];
      if(type == 'png' || type == 'jpg' || type == 'jpeg') {

        this.anuncio_image = true;
        this.anuncio_video = false;
      } else if(type == 'mp4') {

        this.anuncio_video = true;
        this.anuncio_image = false;
      }

      if(data['code'] != this.global.get_banner_code()){
          this.global.save_banner_code(data['code']);
          this.modalService.open(this.publicity);
      }
    });
  }

  calc_change(){

    // Calcula del total
    this.data.l_value_currency = this.global.calc(this.data);

    // Calcula el ahorro
    this.saving = this.global.get_saving(this.data,this.res);
  }

  ttoggle(){
    var t = document.getElementsByClassName('_tooltip')[0];
    t.classList.toggle('tactive');
  }

  ttoggle2(){
    var t = document.getElementsByClassName('_tooltip2')[0];
    t.classList.toggle('tactive');
  }
  

  toggle_currency(){
    this.first_currency = (this.first_currency == "Qtz" ? "Dol" : "Qtz");
    this.last_currency = (this.last_currency == "Dol" ? "Qtz" : "Dol");

    if(this.first_currency == "Qtz"){

      var get_currency = this.global.format_exchange_rate(this.res.gtx_sell);
      this.data.change_type = get_currency;
      // this.f_value_currency = get_currency;

      this.data.type = 'sell';
      this.f_simbol_c = "Q";
      this.l_simbol_c = "$";

    }else{

      var get_currency = this.global.format_exchange_rate(this.res.gtx_buy);
      this.data.change_type = get_currency;
      // this.f_value_currency = get_currency;

      this.data.type = 'buy';
      this.f_simbol_c = "$";
      this.l_simbol_c = "Q";
    }

    this.calc_change();
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  close() {
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get_alert(){
    var user_id = this.global.get_user_id();
    var header = this.global.get_auth_headers();

    this.http.get(this.global.api_url()+'clients/'+user_id+'/alert/get',header)
      .subscribe(data=>{
        if(data['data']){
          this.buy_alert = parseFloat(data['data'].buy_alert).toFixed(2);
          this.sell_alert = parseFloat(data['data'].sell_alert).toFixed(2);
        }
      });
  }

  activate_alert(){
    if(!this.global.is_login()) this.router.navigate(['/login']);

    var header = this.global.get_auth_headers();
    var user_id = this.global.get_user_id();

    var parameters = JSON.stringify({
      buy:this.buy_alert,
      sell:this.sell_alert,
      buy_alert_is_active:1,
      sell_alert_is_active:1
    });

    this.http.post(this.global.api_url()+'clients/'+user_id+'/alert/update',parameters,header)
      .subscribe(data=>{
        if(data){
          this.global.show_alert('success',this.translate.instant('m3'),this.translate.instant('m27'));
        }else{
          this.global.show_alert('error','Error',this.translate.instant('m28'));
        }
      },error=>{
        this.global.show_alert('error','Error',this.translate.instant('m28'));
      });
  }

  get_accounts(){

    var user_id = this.global.get_user_id();
    var headers = this.global.get_auth_headers();

    this.http.get(this.global.api_url()+'clients/'+user_id+'/me',headers)
      .subscribe(data=>{

        this.type = (data['user'].client_ive == false || data['user'].client_ive == 2 || data['user'].client_ive == null ? 2 : 1);

      },error=>{});

    this.http.get(this.global.api_url()+'clients/'+user_id+'/accounts',headers)
      .subscribe(data=>{

        if(data['data'].length > 0){
          var res = [];
          var accounts = [];
          data = data['data'];

          for(let d in data){
            if(!accounts.includes(data[d].bank.name)){
              res.push(data[d]);
              accounts.push(data[d].bank.name);
            }
          }

          this.res = this.global.get_best_change(res);
          var gtx_buy = this.global.format_exchange_rate(this.res.gtx_sell);
            this.f_value_currency = gtx_buy;
            this.data.change_type = gtx_buy;

          this.data.f_value_currency = 1000;
          // this.calc_change();
          this.toggle_currency();
           
        }else{
          this.get_exchange_rate();
        }

      });
  }

  get_exchange_rate(){
    this.global.get_exchange_rate().then(data=>{

      this.res = {
          gtx_buy:data['buy'].best,
          gtx_sell:data['sell'].best,
          points_buy:data['buy'].points_buy,
          points_sell:data['sell'].points_sell
          // buy:data['buy']['data'].gtx_buy,
          // sell:data['sell']['data'].gtx_sell,
          // points_buy:data['buy']['data'].points_buy,
          // points_sell:data['sell']['data'].points_sell
      };

      var change = this.global.format_exchange_rate(this.res.gtx_sell);
        this.data.change_type = change;

        this.data.f_value_currency = 1000;
        // this.calc_change();
        this.toggle_currency();
    });
  }

  videoEnd() {
       this.global.send_medal_event(1);
  }

  ngOnInit() {

    // document.getElementById('bg_video').addEventListener('ended',this.myHandler,false);
   

    var t = document.getElementById('_tooltip');
    var c = document.getElementById('circle');

    var t2 = document.getElementById('_tooltip2');
    var c2 = document.getElementById('circle2');

    document.addEventListener('click', function(event:any) {
      var isClickInside = t.contains(event.target);
      var isClickInside2 = c.contains(event.target);
      var isClickInside3 = t2.contains(event.target);
      var isClickInside4 = c2.contains(event.target);

      if (!isClickInside && !isClickInside2 && !isClickInside3 && !isClickInside4) {
        t.classList.remove('tactive');
        t2.classList.remove('tactive');
      }
    });

    if(this.is_login){ this.get_alert(); this.get_accounts(); }
    if(!this.is_login) this.get_exchange_rate();
  }
}