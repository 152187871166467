import { Component, OnInit, Input } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-history-chart',
  templateUrl: './history-chart.component.html',
  styleUrls: ['./history-chart.component.css'],
  providers: [GlobalService]
})
export class HistoryChartComponent implements OnInit {

  public chartType: string = 'line';
  isDataAvailable:Promise<boolean>;
  range;

  @Input() res: any;
  @Input() filter_type: any;
  // @Input() isDataAvailable: Promise<boolean>;
  public chartDatasets: ChartDataSets[] = [
    // { data: this.exchange_buy(), label: 'Compra',fill: false, pointRadius: 0,},
    // { data: this.exchange_sell(), label: 'Venta',fill: false, pointRadius: 0,},
  ];

  public chartLabels: Label[] = [];

  public chartColors: Array<any> = [
    {
      backgroundColor: '#49A1D1',
      borderColor: '#49A1D1',
      borderWidth: 5,
    },
    {
      backgroundColor: '#3ACC6C',
      borderColor: '#3ACC6C',
      borderWidth: 5,
    }
  ];

  public chartOptions: ChartOptions =  {};

  exchange_buy(){
    if(this.res == null) return;
    var args = [];
    for(var d = 0; d < this.res.length; d++){
      args.push(this.res[d].buy);
    }

    return args;
  }

  exchange_sell(){
    if(this.res == null) return;
    var args = [];
    for(var d = 0; d < this.res.length; d++){
      args.push(this.res[d].sell);
    }

    return args;
  }

  get_dates(){
    if(this.res == null) return;
    var args = [];

    for(var d = 0; d < this.res.length; d++){
      switch (this.filter_type) {
        case "day":
            args.push(this.global.format_date(this.res[d].created_at));
        break;

        case "week":
            args.push(this.global.format_date(this.res[d].year+"-"+this.res[d].month+"-"+(parseInt(this.res[d].day) + 1)));
        break;

        case "month":
            args.push(this.global.format_date(this.res[d].created_at));
        break;

        case "year":
            args.push(this.global.format_month(this.res[d].month)+" "+this.res[d].year);
        break;

        case "all":
            args.push(this.res[d].year);
        break;

        default:
            args.push(this.res[d].created_at);
        break;
      }
    }

    return args;
  }

  get_ranges(){

    var args = [];
    for(var d = 0; d < this.res.length; d++){
      args.push(this.res[d].sell);
      args.push(this.res[d].buy);
    }

    var range = args.sort((n1,n2) => n2 - n1);

    return {
      max:parseFloat(range[0]),
      min:parseFloat(range[range.length - 1])
    }
  }

  constructor(
    private global: GlobalService
  ){}

  ngOnInit(){}

  ngAfterViewInit(){
    this.draw();
  }

  draw(){

    var buy = this.exchange_buy();
    var sell = this.exchange_sell();

    this.chartDatasets = [
      { data: buy, label: 'Compra',fill: false, pointRadius: 0,},
      { data: sell, label: 'Venta',fill: false, pointRadius: 0,},
    ];

    var range = this.get_ranges();
    var max = (range.max + 0.01);
    var min = (range.min - 0.01);

    this.chartOptions = {
      responsive: true,
      tooltips: {
        mode: 'x-axis'
        // enabled: true,
        // mode: 'single',
        //  callbacks: {
        //   label: function (tooltipItems, data) {
        //     return data.datasets[0].data[tooltipItems.index];
        //   }
        // }
      },
      scales : {
        yAxes: [
          {
            gridLines: {
              drawBorder: false,
            },
            ticks: {
                max : max,
                min: min,
                stepSize:.010
            },
          }
        ],
        xAxes: [{
          gridLines: {
            display: false,
            drawBorder: false
          },
        }],
        // stepSize:.001
      },
    }

    this.chartLabels = this.get_dates();
    this.isDataAvailable = Promise.resolve(true);
  }

}