import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { HttpClient } from '@angular/common/http';
import { TermsAndConditionsComponent } from '../terms-and-conditions/terms-and-conditions.component';
import { TranslateService } from '@ngx-translate/core';  

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  type:number = 0;
  data:any = {};
  twilio_token:number = 0;
  invalid_sms:boolean = false;
  send:any = {};
  terms:any;
  referred:string;
  check_terms:boolean = true;
  
  resolve_nationalities:Promise<boolean>;
  nationalities:any = [];

  resolve_activities:Promise<boolean>;
  activities:any = [];

  resolve_departaments:Promise<boolean>;
  departaments:any = [];

  resolve_municipies:Promise<boolean>;
  municipies:any = [];
  pdf_zoom:any =1;

  @ViewChild('content', { static: false }) content;
  @ViewChild('terms', { static: false }) TermsAndConditionsComponent: TermsAndConditionsComponent;
  constructor(
    private modalService: NgbModal,
    private _route: ActivatedRoute,
    public global:GlobalService,
    private http:HttpClient,
    private translate:TranslateService) {
    this._route.paramMap.subscribe((params: ParamMap) => {
      this.type = +params.get('type');
      this.referred = (params.get('id') != null ? params.get('id') : "-1");
    });
  }

  zoom(val){
    if(this.pdf_zoom == 1 && val == 0) return;

    if(val == 1){
      this.pdf_zoom += 0.5;
    }else{
      this.pdf_zoom -= 0.5;
    }
  }

  get_nationalities(){
    this.global.get_nationalities()
      .then((res:any)=>{
        
        this.nationalities = res;
        this.resolve_nationalities = Promise.resolve(true);

      }).catch((res)=>{

      });
  }

  change_departament(){
    this.global.get_municipies(this.data.direccion_departamento)
      .then((res)=>{
        this.municipies = res;
        this.resolve_municipies = Promise.resolve(true);
      });
  }

  get_activities(){
    this.global.get_activities()
      .then((res)=>{
        this.activities = res;
        this.resolve_activities = Promise.resolve(true);
      });
  }

  get_departaments(){
    this.global.get_departaments()
      .then((res)=>{
         this.departaments = res;
         this.resolve_departaments = Promise.resolve(true);
      });
  }

  ngOnInit(){
    this.get_nationalities();
    this.get_activities();
    this.get_departaments();
    // window.addEventListener('scroll', this.scroll, true);
  }

  get_total_height(){
    var content = document.getElementById('page-container').getElementsByClassName('pf');
    var height = 0;
    for(let d in content){
      if(content[d].clientHeight == undefined) continue;
      height += content[d].clientHeight;
    }
    return height - 500;
  }

  ngAfterViewInit(){
    var doom = this;
    var content = document.getElementById('page-container');
    var total_height = this.get_total_height();
    content.addEventListener('scroll',function(e){
        if(e.target['scrollTop'] >= total_height){
          doom.check_terms = false;
        }
    });
  }

  verify_sms(){

    this.global.show_loading();
    this.modalService.dismissAll();

    // Verify inputs
    this.data.client_type = (this.type == 1 ? 'individual' : 'business');

    // Verify individual inputs
    if(this.data.client_type == 'individual'){
      if(
        this.data.first_name == null ||
        this.data.first_surname == null ||
        this.data.last_surname == null ||
        this.data.tipo_documento == null ||
        this.data.documento == null ||
        this.data.user_email == null ||
        this.data.user_phone == null ||
        this.data.password == null
      ){
        this.global.show_alert('error','Error',this.translate.instant('m2'));
        return false;
      }
    }

    if(!this.terms){
      this.global.show_alert('error','Error',this.translate.instant('m32'));
      return false;
    }

    // Verify business inputs
    if(this.data.client_type == 'business'){

      if(
        this.data.first_name == null ||
        this.data.first_surname == null ||
        this.data.last_surname == null ||

        //this.data.nacionalidad == null ||
        this.data.actividad_economica == null ||
        // this.data.fecha_nacimiento == null ||
        this.data.direccion_detalle == null ||
        this.data.tipo_documento == null ||  
        this.data.documento == null ||

        this.data.user_email == null ||
        this.data.user_phone == null ||
        this.data.password == null ||
        this.data.company_name == null ||
        this.data.company_nit == null ||
        this.data.company_phone == null
        //this.data.user_birthdate == null
      ){
        this.global.show_alert('error','Error',this.translate.instant('m2'));
        return false;
      }
    }

    // Validate passwords
    if(this.data.password !== this.data.confirm_password){
      this.global.show_alert('error','Error',this.translate.instant('m33'));
      return false;
    }

    this.data.sms = "";
    this.invalid_sms = false;

    var parameters = JSON.stringify({user_phone:'+502'+this.data.user_phone,user_email:this.data.user_email});
    var headers = this.global.get_auth_headers();

    this.http.post(this.global.api_url()+'auth/verify_twilio_token',parameters,headers)
      .subscribe(data=>{

          this.global.hide_loading();

          if(data['status'] == "success"){
            this.twilio_token = data['token'];
          }

          this.modalService.open(this.content);
      },error=>{
          this.global.hide_loading();
      });
  }

  register(){
    this.data.client_type = (this.type == 1 ? 'individual' : 'business');

    if(this.referred) this.send.referred = this.referred;

    if(this.data.client_type == 'individual'){      
        this.send.first_name = this.data.first_name;
        this.send.last_name = this.data.last_name;
        this.send.first_surname = this.data.first_surname;
        this.send.last_surname = this.data.last_surname;
        this.send.user_birthdate = this.data.user_birthdate;
        // this.send.nacionalidad = this.data.nacionalidad;
        // this.send.actividad_economica = this.data.actividad_economica;
        // this.send.direccion_detalle = this.data.direccion_detalle;
        // this.send.direccion_departamento = this.data.direccion_departamento;
        // this.send.direccion_municipio = this.data.direccion_municipio;
        this.send.tipo_documento = this.data.tipo_documento;
        this.send.documento = this.data.documento;
        this.send.user_email = this.data.user_email,
        this.send.user_phone = this.data.user_phone,
        this.send.password = this.data.password;
        this.send.client_type = this.data.client_type
    }else{

        //Registro juridico
        this.send.first_name = this.data.first_name;
        this.send.last_name = this.data.last_name;
        this.send.first_surname = this.data.first_surname;
        this.send.last_surname = this.data.last_surname;
        //this.send.user_birthdate = this.data.user_birthdate;        
        //this.send.nacionalidad = this.data.nacionalidad;
        // this.send.fecha_nacimiento = this.data.fecha_nacimiento;
        this.send.actividad_economica = this.data.actividad_economica;
        this.send.direccion_detalle = this.data.direccion_detalle;
        this.send.direccion_departamento = this.data.direccion_departamento;
        this.send.direccion_municipio = this.data.direccion_municipio;
        this.send.tipo_documento = this.data.tipo_documento;
        this.send.documento = this.data.documento;
        this.send.user_email = this.data.user_email;
        this.send.user_phone = this.data.user_phone;
        this.send.password = this.data.password;
        this.send.client_type = this.data.client_type;
        this.send.company_name = this.data.company_name;
        this.send.company_trade_name = this.data.company_trade_name;
        this.send.company_nit = this.data.company_nit;
        this.send.company_phone = this.data.company_phone;
    }

    this.global.show_loading();

    var parameters = JSON.stringify(this.send);
    var headers = this.global.get_headers();

    this.http.post(this.global.api_url()+'auth/clients',parameters,headers)
      .subscribe(data=>{
        if(data['data'].status == 'success'){
          this.close();
          this.global.show_alert('success','Exito',data['data'].message,'login');
        }else{
          this.close();
          this.global.show_alert('error','Error',this.translate.instant('m2'));
        }
      },error=>{
        this.close();
        var res = error.error['Errores'];
        this.global.show_alert('error','Error',res);

        // for(let d in res){
        //   console.log(res[d]);
        // }

        // this.global.show_alert('error','Error',(error.error['Errores'][0] != undefined || error.error['Errores'][0] != null ? error.error['Errores'][0] : 'Verifique que todos los campos estén llenos'));


        // this.global.show_alert('Error','Ocurrió un error, intente más tarde');
      });
  }

  open() {
    this.modalService.open(this.content)
  }
  close() {
    this.modalService.dismissAll();
  }

  validate_sms_code(){
    if(this.twilio_token == this.data.sms){
      this.register();
    }else{
      this.invalid_sms = true;
    }
  }
}
