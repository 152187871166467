import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-account',
  templateUrl: './new-account.component.html',
  styleUrls: ['./new-account.component.css'],
  providers: [GlobalService]
})
export class NewAccountComponent implements OnInit {

  bank:any = [];
  data:any = {};
  resolve_bank: Promise<boolean>;
  is_login:any = false;
  url_cache:any;
  userdata:any;
  session:any;

  constructor(
      private modalService: NgbModal,
      private http:HttpClient,
      private global:GlobalService,
      private router:Router,
      private translate:TranslateService
    ){

    router.events.subscribe((eve:any) => {
      if(eve.url == this.url_cache || eve.url == undefined) return;
    });
  }

  @Output() sendData = new EventEmitter<any>();
  @ViewChild('thirdcontent', {static: false}) content;
  ngOnInit(){
    this.global.is_login().then((res)=>{
      if(res) this.list_banks();
    });

    this.userdata = this.global.get_userdata();
    this.session = this.global.get_session();
  }

  set_account_property(){
    this.session;
    if(this.session.company != null && this.session.company.client_id != null){
      this.data.account_name = this.session.company.company_name + " / " + this.session.company.company_trade_name;
    }else{
      this.data.account_name = this.userdata.user_first_name + ' ' + this.userdata.user_first_surname;
    }
  }

  list_banks(){

    var headers = this.global.get_auth_headers();

    this.http.get(this.global.api_url()+'banks',headers)
      .subscribe(data=>{

        var get = [];
        var res = data['data'];

        for(var d in res){
          get.push({
            id:res[d].id,
            name:res[d].name,
            identify:res[d].identify,
            color:res[d].color,
            active:res[d].active
          });
        }

        this.bank = get;
        this.resolve_bank = Promise.resolve(true);

      },error=>{
        
      });
  }

  reset_form(){
    this.data.account_number = '';
    // this.data.account_name = '';
    this.data.account_identify = '';
  }

  add_account(){

    if(this.data.account_type_id == null) this.data.account_type_id = 1;

    if(
      this.data.account_number == null ||
      this.data.account_name == null ||
      this.data.account_identify == null
    ){
      this.global.show_alert('warning','Error',this.translate.instant('m2'));
      return;
    }

    this.global.show_loading();
    this.validate_account().then((res)=>{

        if(!res) return;

        var id = this.global.get_user_id();
        var parameters = JSON.stringify(this.data);
        var headers = this.global.get_auth_headers();

        this.http.post(this.global.api_url()+'clients/'+id+'/accounts',parameters,headers)
          .subscribe(data=>{

            this.reset_form();
            this.global.show_alert("success",this.translate.instant('m3'),this.translate.instant('m4'));
            this.sendData.emit(this.bank);
            this.modalService.dismissAll();

          },error=>{
            this.global.show_alert('error','Error',error.error.error.message);
          });

      }).catch((res)=>{

      });
  }

  open() {
    this.modalService.open(this.content, {size: 'lg'})
  }

  close() {
    this.modalService.dismissAll();
  }

  validate_account(){

    var pr = new Promise((resolve, reject) => {
      var headers = this.global.get_auth_headers();
      var parameters = JSON.stringify({
        bank_id:this.data.bank_id,
        currency_id:this.data.currency_id,
        account_type_id:this.data.account_type_id,
        account_number:this.data.account_number
      });

      this.http.post(this.global.api_url()+'validar_cuentas',parameters,headers)
        .subscribe(data=>{
          if(data){
            if(data['code'] == 200){
              resolve(true);
            }else{
              this.global.show_alert('error','Error',this.translate.instant('m5'));
              resolve(false);
            }
          }
        },error=>{
          resolve(false);
        });
    });

    return pr;
  }

  check_type_account(){
    if(this.data.account_type_id == 3){
      this.data.currency_id = 3;
    }
  }
}
