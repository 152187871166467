// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  	production: false,
  	firebase: {
	  	apiKey: "AIzaSyC51O32zewUsnLB_EYQg2LLQ_8ImzFgFxg",
		authDomain: "gtxapp-282817.firebaseapp.com",
		databaseURL: "https://gtxapp-282817.firebaseio.com",
		projectId: "gtxapp-282817",
		storageBucket: "gtxapp-282817.appspot.com",
		messagingSenderId: "508206209856",
		appId: "1:508206209856:web:c53dcc6d2a2bc9d654b8a5",
		measurementId: "G-PSLFN0KXYK"
	},
	product_name:'Negociación de divisas',
	show_disconnected:false,
	comission:0,
	mail:'soporte@guatexchange.com',
	lang:'es',
	limit:30
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
