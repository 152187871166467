import { Component, ErrorHandler } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements ErrorHandler {
  	title = 'Guatexchange';

    handleError(error: any): void {
     const chunkFailedMessage = /Loading chunk [\d]+ failed/;

      if (chunkFailedMessage.test(error.message)) {
        window.location.reload();
      }
    }

    constructor(
      public translate: TranslateService,
      private global: GlobalService
    ) {

        var lang = this.global.get_lang();

        translate.setDefaultLang(lang);
        translate.use(lang);

        // translate.addLangs(['es','en']);
        // translate.setDefaultLang(lang);
        // const b = translate.getBrowserLang();
        // translate.use(b.match(/es|en/) ? b : 'es');
    }
}
