import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewAccountComponent } from '../shared/new-account/new-account.component';
import { GlobalService } from '../services/global.service';
import { PushNotiicationsService } from '../services/push-notifications.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  providers: [PushNotiicationsService]
})
export class HeaderComponent implements OnInit {
  
  isCollapsed: boolean = true;
  isLoggedin:any = false;
  user:any = "";
  session:any;
  notification:any;
  resolve_notification: Promise<boolean>;
  notifications_is_not_available: Promise<boolean>;
  firebase_id:any = "";
  url_cache:any;
  show_disconnected:any = environment.show_disconnected;
  new_notifications:boolean = false;
  medal_img:string;
  medal_description:string;
  modal_open:boolean = false;
  medal_league = '../../assets/reward-icon.png';
  company:any;
  params:any;
  business:any = {};

  @ViewChild(NewAccountComponent, {static: false}) newAccountComponent: NewAccountComponent;
  @ViewChild("medal",{static: false}) medal: TemplateRef<any>;

  constructor(
    private modalService: NgbModal,
    private router:Router,
    public global:GlobalService,
    private http: HttpClient,
    private push: PushNotiicationsService
  ){

    this.run();

    router.events.subscribe((eve:any) => {
      if(eve.url == this.url_cache || eve.url == undefined) return;
      this.url_cache = eve.url;
      this.run();
    });
  }

  run(_data = ""){

    try{
      if(_data['data']['type'] == 'medal'){
        if(!this.modal_open){

          this.medal_img = _data['data']['img'];
          this.medal_description = _data['data']['description'];
          this.modal_open = true;
          this.modalService.open(this.medal, {});
        }
      }
    }catch(e){}

    this.global.is_login().then((res)=>{
      this.isLoggedin = res;
      if(res){
       this.global.core();
       this.global.request_core().then((r)=>{
         if(r['league'] && r['league']['id'] > 0) this.medal_league = r['league']['img'];
         r['business'] != null ? this.business = r['business'] : {};
         this.show_notifications(r['notifications']);
         this.global.set_saving(r['saving']);
         this.global.set_commission(r['admin_settings']['commission']);
         if(r['admin_settings']['banner_status']) this.global.set_message(r['admin_settings']['banner']);
       });
      }
    });

    this.user = this.global.get_userdata();
    this.session = this.global.client_user();
  }

  show_notifications(data){

    for(let d in data){
      if(data[d].read == 0) this.new_notifications = true;
    }

    if(data != null){
      this.notification = data;
      this.resolve_notification = Promise.resolve(false);
    }else{
      this.resolve_notification = Promise.resolve(true);
    }
  }

  updateToken(){
    var user_id = this.global.get_user_id();
    var headers = this.global.get_auth_headers();
    var parameters = JSON.stringify({firebase_id:this.firebase_id});

    if(!user_id) return;

    // Actualizar Firebase ID
    // this.http.post(this.global.api_url()+'save_firebase',parameters,headers)
    this.http.post(this.global.api_url()+'clients/'+user_id+'/accounts/updateFirebaseId',parameters,headers)
      .subscribe(res=>{},error=>{});
  }

  ngOnInit() {

    // this.global.get_parameters().then((res)=>{
    //   if(res['code'] != 200) return;
    //   this.global.set_broadcast_parameters(res['data']);
    // });

    // var doom = this;
    // setTimeout(function(){
    //   doom.modalService.open(doom.medal, {});
    // },3000);

    this.global.listenNotification.subscribe((res)=>{
      this.run(res);
    });

    this.global.listenBroadcastSession.subscribe((res)=>{
      try{
        this.company = this.global.get_company();
      }catch(e){}
    });

    try{
      this.push.getSubscription().then(()=>{
        this.push.getToken().then((token)=>{
          this.firebase_id = token;
          this.notifications_is_not_available = (this.firebase_id != null ? Promise.resolve(true) : Promise.resolve(false));
          if(this.firebase_id != null) this.updateToken();
        });

        this.push.watchMessages();
      });
    }catch(e){}
  }

  logout(){
    this.global.remove_session();
    this.global.is_login().then((res)=>{
      this.isLoggedin = res;
    });
    this.router.navigate(['/']);
  }

  open(){
    this.newAccountComponent.open();
  }

  close() {
    this.modalService.dismissAll('');
    this.modal_open = false;
  }

  news(){
    this.router.navigate(['/news']);
  }

  cancelPermission(){
    this.push.cancelPermission().then(console.log);
  }

  activatePermission(){
    this.push.requestPermission().then(console.log);
  }

  notifications(){
    if(!this.new_notifications) return;

    var headers = this.global.get_auth_headers();
    var user_id = this.global.get_user_id();
    this.http.get(this.global.api_url()+'clients/'+user_id+'/readed_notifications',headers)
      .subscribe((res)=>{
        if(res) this.new_notifications = false;
      });
  }
}