import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

	list:any;	

	constructor() { 
		this.list = document.getElementsByClassName('item');
	}

	ngOnInit() {

	}

	toggle(val){
		var c = 0;
		for(let d in this.list){

			if(c == val){
				this.list[d].classList.add('item_active');
				this.list[d].nextSibling.classList.add('fa-chevron-up');
				this.list[d].nextSibling.classList.remove('fa-chevron-down');
			}

			c++;
		}
	}

	open_sub(event){
		if(event.target.classList.contains('sub_icon')){
			event.target.parentElement.nextSibling.classList.toggle('sub_active');
		}else{
			event.target.nextSibling.classList.toggle('sub_active');
		}
	}
}
