import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

	doom = this;
	interval:any = null;

	private saving = new BehaviorSubject<number>(0);
	listenSaving = this.saving.asObservable();

	private message = new BehaviorSubject<string>("");
	listenMessage = this.message.asObservable();

	private commission = new BehaviorSubject<number>(environment.comission);
	listenCommission = this.commission.asObservable();

	private notification = new BehaviorSubject<any>(null);
	listenNotification = this.notification.asObservable();

	private opeartion_status = new BehaviorSubject<any>(null);
	listenOperationStatus = this.opeartion_status.asObservable();

	private broadcast_session = new BehaviorSubject<any>(null);
	listenBroadcastSession = this.broadcast_session.asObservable();

	private broadcast_parameters = new BehaviorSubject<any>(null);
	listenParameters = this.broadcast_parameters.asObservable();

	private broadcast_ive_individual = new BehaviorSubject<any>(null);
	listenIndividual = this.broadcast_ive_individual.asObservable();

	private broadcast_ive_business = new BehaviorSubject<any>(null);
	listenBusiness = this.broadcast_ive_business.asObservable();

	constructor(
		private http:HttpClient,
		private router:Router,
		private translate: TranslateService
	){}

  	base_url(){
		return 'https://guatexchange.com/';
		//return 'https://dev4.guatexchange.com/';
		// return 'http://143.208.181.174/';
		
	}

//----------------------------------------------------------------------

	admin_url(){
		return 'https://backoffice.guatexchange.com/';
		// return 'http://143.208.181.174/';
		// return 'http://107.178.209.61/';
	}

//----------------------------------------------------------------------

	request_core(){

		var pr = new Promise((resolve,reject)=>{
			var user_id = this.get_user_id();
			var headers = this.get_auth_headers();

			this.http.get(this.api_url()+'clients/'+user_id+'/core',headers)
				.subscribe((res)=>{
					resolve(res['data']);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_parameters(){
		return new Promise((resolve,reject)=>{
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_parameters',headers)
				.subscribe((res)=>{
					resolve(res);
				});
		});
	}

//----------------------------------------------------------------------

	set_broadcast_parameters(data:any){
		this.broadcast_parameters.next(data);
	}

//----------------------------------------------------------------------

	set_broadcast_individual_ive(data:any){
		this.broadcast_ive_individual.next(data);
	}

//----------------------------------------------------------------------

	set_broadcast_business_ive(data:any){
		this.broadcast_ive_business.next(data);
	}

//----------------------------------------------------------------------

	set_broadcast_session(data:any){
		this.broadcast_session.next(data);
	}

//----------------------------------------------------------------------

	set_operation_status(data:any){
		this.opeartion_status.next(data);
	}

//----------------------------------------------------------------------

	set_saving(data:number){
		this.saving.next(data);
	}

//----------------------------------------------------------------------

	set_message(data:string){
		this.message.next(data);
	}

//----------------------------------------------------------------------

	set_notification(data:any){
		this.notification.next(data);
	}

//----------------------------------------------------------------------

	set_commission(data:number){
		this.commission.next(data);
	}

//----------------------------------------------------------------------

	api_url(){
		// return 'http://107.178.209.61/api/v1/';
		return 'https://backoffice.guatexchange.com/api/v1/';
		// return 'http://127.0.0.1:8000/api/v1/' /*test*/
	}

//----------------------------------------------------------------------

	get_headers(){

		const httpOptions = {
		  headers: new HttpHeaders({
		    'Content-Type':  'application/json'
		  })
		};

		return httpOptions;
	}

//----------------------------------------------------------------------

	get_year_options(){
		var current_year = this.get_current_year();
		var res = [];

		for(var x = (current_year - 18); x > (current_year - 118) && current_year > 0; x--){
			res.push(x);
		}

		return res;
	}

//----------------------------------------------------------------------

	set_session(data){
		localStorage.setItem('user',JSON.stringify(data));
		this.set_broadcast_session([]);
	}

//----------------------------------------------------------------------

	get_session(){
		var user = JSON.parse(localStorage.getItem('user'));
		return user;
	}

//----------------------------------------------------------------------

	get_userdata(){
		var user = this.get_session();
		if(user != null){
			return user.client_user[0].user;
		}else{
			return false;
		}
		
	}

//----------------------------------------------------------------------

	client_user(){
		var user = this.get_session();
		if(user != null){
			return user.client_user[0];
		}else{
			return false;
		}
	}

//----------------------------------------------------------------------

	remove_session(){
		localStorage.removeItem('exchange');
		localStorage.removeItem('user');
	}

//----------------------------------------------------------------------

	show_alert(type,title,description:any = "",action:any = '',pr:boolean = false){

		var desc = "";
		var count = 0;

			var promise = new Promise((resolve, reject) => {

				if(typeof(description) == "object"){
					for(let d in description){
						desc += description[d]+"<br><br>";
						count++;
					}
				}else{
					desc = description;
				}

				Swal.fire({
				  icon: type,
				  title: title,
				  html: desc,
				  showCloseButton: pr,
			  	  showCancelButton: pr,
				}).then((result) => {

					try{
					  if (action != '') this.router.navigate([action]);
					  if(pr) resolve((result.value ? result.value : false));
					}catch(e){}

				});

		}); /*End promise*/

		return promise;
	}

//----------------------------------------------------------------------

	is_login(){
		var pr = new Promise((resolve,reject)=>{
			var user = JSON.parse(localStorage.getItem('user'));
			return resolve((user != null ? true : false));
		});

		return pr;
	}

//----------------------------------------------------------------------

	verify_ive(){
		var user = JSON.parse(localStorage.getItem('user'));

		if(user != null){

			var ive = user.client_user[0].client_ive;

			if(ive == 1) return 1;
			return 2;

		}else{
			return 1;
		}
	}

//----------------------------------------------------------------------

	get_token(){
		var user = this.get_session();
		if(user != null){
			return user.access_token;
		}else{
			return false;
		}
		
	}

//----------------------------------------------------------------------

	get_auth_headers(){
		const httpOptions = {
		  headers: new HttpHeaders({
		    'Content-Type':  'application/json',
		    'Authorization': 'Bearer '+this.get_token()
		  })
		};

		return httpOptions;
	}

//----------------------------------------------------------------------

	get_user_id(){
		var user = this.get_session();		
		return (user != null ? user.client_user[0].client_id : "");
	}

//----------------------------------------------------------------------

	get_business_id(){
		var user = this.get_session();
		return user;
	}

//----------------------------------------------------------------------

	get_currencies(){
		var headers = this.get_auth_headers();
		return this.http.get(this.api_url(),headers)
			.subscribe(data=>{
				return data;
			});
	}

//----------------------------------------------------------------------

	get_verify_session(doom:any){

		var promise = new Promise((resolve, reject) => {
			var headers = doom.get_auth_headers();
			doom.http.get(doom.api_url()+"auth/verify_session",headers)
				.subscribe(data=>{

					resolve({
						'code':200,
						'data':true
					});

				},error=>{
					if(error.error != null){
						if(error.error.error == 'Unauthenticated.'){
							doom.remove_session();
							doom.show_alert("error",'Su sesión ha expirado',"","/login");

							resolve({
								'code':203,
								'data':false
							});
						}else{
							resolve({
								'code':404,
								'data':true,
								'reason':'connection'
							});
						}
					}else{
						doom.remove_session();
						doom.show_alert("error",'Su sesión ha expirado',"","/login");
						resolve({
								'code':404,
								'data':true,
								'reason':'connection'
							});
					}
				});
		});

		return promise;
	}

//----------------------------------------------------------------------

	set_exchange_rate(data:any){
		localStorage.setItem('exchange',JSON.stringify({
			buy:data.buy,
			sell:data.sell
		}));
	}

//----------------------------------------------------------------------

	exchange_rate(){
		return JSON.parse(localStorage.getItem('exchange'));
	}

//----------------------------------------------------------------------

	get_exchange_rate(){
		var promise = new Promise((resolve, reject) => {

			var headers = this.get_auth_headers();
			var user_id = this.get_user_id();
			var promise;

			this.http.get(this.api_url()+'exchange_rates',headers)
				.subscribe(data=>{
					
					this.set_exchange_rate(data);
					resolve(data);

				},error=>{
					resolve(false);
				});
		  });

		return promise;
	}

	get_exchange_rateOld(){
		var headers = this.get_auth_headers();
		var user_id = this.get_user_id();

		this.http.get(this.api_url()+'clients/'+user_id+'/exchange_rates',headers)
			.subscribe(data=>{
				this.set_exchange_rate(data);
			},error=>{});
	}

//----------------------------------------------------------------------

	get_account_best_change(){

		var promise = new Promise((resolve,reject)=>{
			var user_id = this.get_user_id();
			var headers = this.get_auth_headers();

			this.http.get(this.api_url()+'clients/'+user_id+'/accounts',headers)
				.subscribe(res=>{
					resolve(this.get_best_change(res['data']));
				},error=>{
					resolve(false);
				});
		});

		return promise;
	}

//----------------------------------------------------------------------

	request_me(){
		var pr = new Promise((resolve,reject)=>{
			var user_id = this.get_user_id();
			var headers = this.get_auth_headers();
			this.http.get(this.api_url()+'clients/'+user_id+'/me2',headers)
		      .subscribe(data=>{

		        resolve(data['user']);

		      },error=>{
		      	reject(false);
		      });
		  });

		return pr;
	}

//----------------------------------------------------------------------

	// Validate ive form
	get_about_me(){
		
		var pr = new Promise((resolve,reject)=>{
			var user_id = this.get_user_id();
			var headers = this.get_auth_headers();
			this.http.get(this.api_url()+'clients/'+user_id+'/ive',headers)
		      .subscribe(data=>{

		        // resolve((data['user'].client_ive ? data['user'].client_ive : 0));
		        resolve(data);

		      },error=>{
		      	reject(false);
		      });
		  });

		return pr;
	}

//----------------------------------------------------------------------

	/**
	 * Compra $ => Q
	 * Venta Q => $
	 * Obtener la tasa mas alta en compra
	 * Obtener la tasa mas baja en venta
	 * @param data
	 */
	get_best_change(data){
		var args = {};
		var buy = [];
		var sell = [];
		var gtx_buy = [];
		var gtx_sell = [];
		var points_buy = 0;
		var points_sell = 0;

		try{
			for(let d in data){
				//if(data[d].account_property_type != 'third'){ /*Evitar tomar en cuenta, cuentas de tercero*/

					// Obtener menor tasa de compra y venta GTX
					gtx_buy.push(data[d].bank.exchange_rate.gtx_buy);
					gtx_sell.push(data[d].bank.exchange_rate.gtx_sell);

					// Obtener la mejor tasa de compra y venta relacionada a bancos
					buy.push(data[d].bank.exchange_rate.buy);
					sell.push(data[d].bank.exchange_rate.sell);
				//}
			}

			// Ordenar de menor a mayor
			var sortGtxBuy: number[] = gtx_buy.sort((n1,n2) => n2 - n1);
			var sortGtxSell: number[] = gtx_sell.sort((n1,n2) => n1 - n2);
			var sortBuy: number[] = buy.sort((n1,n2) => n2 - n1);
			var sortSell: number[] = sell.sort((n1,n2) => n1 - n2);

			let l:any = sortGtxBuy[0];
			let l2:any = sortGtxSell[0];

			args = {
				buy:sortBuy[0],
				sell:sortSell[0],
				gtx_buy:parseFloat(l).toFixed(4),
				gtx_sell:parseFloat(l2).toFixed(4),
				points_buy:(sortGtxBuy[0] - sortBuy[0]).toFixed(4),
				points_sell:(sortGtxSell[0] - sortSell[0]).toFixed(4)
			}

		}catch(e){
			args = {
				gtx_buy:0,
				buy:0,
				points_buy:0,
				gtx_sell:0,
				sell:0,
				points_sell:0
			}
		}

		return args;
	}

//----------------------------------------------------------------------

	show_disconnected(){
		environment.show_disconnected = true;
	}

//----------------------------------------------------------------------
	
	hide_disconnected(){
		environment.show_disconnected = false;
	}

//----------------------------------------------------------------------

	core(){

		var doom = this;
		var flag:any = true;

		if(doom.interval == null){
			doom.interval = setInterval(function(){
				doom.is_login().then((res)=>{
					if(res == false){ clearInterval(doom.interval);}
					if(res && flag){
						doom.get_verify_session(doom).then(r=>{
							if(r){ doom.show_disconnected(); }
							flag = r['data'];
						});
					}
				});
			},30000);

			doom.get_verify_session(doom);
		}
	}

//----------------------------------------------------------------------

	format_date(date:any){

		date = new Date(date);
		var monthNames = [
			this.translate.instant('m15'),
			this.translate.instant('m16'),
			this.translate.instant('m17'),
			this.translate.instant('m18'),
			this.translate.instant('m19'),
			this.translate.instant('m20'),
			this.translate.instant('m21'),
			this.translate.instant('m22'),
			this.translate.instant('m23'),
			this.translate.instant('m24'),
			this.translate.instant('m25'),
			this.translate.instant('m26')
		];

		var day = date.getDate();
		var monthIndex = date.getMonth();
		var year = date.getFullYear();

		var ddate = day + ' ' + monthNames[monthIndex] + ' ' + year;

		return (isNaN(day) ? date : ddate);
	}	

//----------------------------------------------------------------------
	
	format_diff_time(date:any){
		
		var now = new Date().getTime();
		date = new Date(date);

		var hours:any = "";
		var minutes:any = "";
		var seconds:any = "";
      
		var distance = now - date;
		var days = Math.floor(distance / (1000 * 60 * 60 * 24));
		hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
		minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
		seconds = Math.floor((distance % (1000 * 60)) / 1000);

		var time = this.translate.instant('m6');
		if(days != 0) return time += days + (days > 1 ? this.translate.instant('m7') : this.translate.instant('m8'));
		if(hours != 0) return time += hours + (hours > 1 ? this.translate.instant('m9') : this.translate.instant('m10'));
		if(hours == 0) return time += minutes + (minutes > 1 ? this.translate.instant('m11') : this.translate.instant('m12'));
	}

//----------------------------------------------------------------------

	format_amount(val,c = 2){
		return parseFloat(val).toFixed(c);
	}

//----------------------------------------------------------------------

	format_exchange_rate(val:any){
		val = parseFloat(val).toFixed(4);
		return val;
	}
	
//----------------------------------------------------------------------

	format_currency(currency:any,type:any = 1){

		return parseFloat(currency).toFixed(4);

	}

//----------------------------------------------------------------------

	format_state(state:string){
		if(state == 'pending') return this.translate.instant('s1');
		if(state == 'process') return this.translate.instant('s2');
		if(state == 'declined') return this.translate.instant('s3');
		if(state == 'success') return this.translate.instant('s4');
		if(state == 'error') return this.translate.instant('s5');
		if(state == 'expired') return this.translate.instant('s6');
		if(state == 'retoken') return this.translate.instant('s7');
		if(state == 'cancelled') return this.translate.instant('s8');
		if(state == 'finished') return this.translate.instant('s9');
		if(state == 'incomplete') return this.translate.instant('s10');
	}

//----------------------------------------------------------------------

	get_individual_ive(){
		return JSON.parse(localStorage.getItem('individual_ive'));
	}

//----------------------------------------------------------------------

	drop_individual_ive(){
		localStorage.removeItem('individual_ive');
	}

//----------------------------------------------------------------------

	set_individual_ive(data){
		localStorage.setItem('individual_ive',JSON.stringify(data));
	}

//----------------------------------------------------------------------

  	convert_to_form_data(dataURI: string) {
        const splitDataURI = dataURI.split(',')
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
  	}

//----------------------------------------------------------------------

	convert_to_binary(dataURI: string){
	   var BASE64_MARKER = ';base64,';
	   var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
	   var base64 = dataURI.substring(base64Index);
	   var raw = window.atob(base64);
	   var rawLength = raw.length;
	   var array = new Uint8Array(new ArrayBuffer(rawLength));

	   for(var i = 0; i < rawLength; i++) {
	     array[i] = raw.charCodeAt(i);
	   }
	   return array;
	}

//----------------------------------------------------------------------

	getPreview(file){
		return new Promise((resolve,reject)=>{
			var _URL = window.URL; /*|| window.webkitURL*/
			var img;
			var res:any = {};

			res.type = file.type;

			var reader = new FileReader();
			reader.onload = (function(theFile) {
			res.name = theFile.name;

			return function(e) {
				if(reader.result != null){
					res.img = reader.result;
					resolve(res);
				}
			};
			})(file);
			reader.readAsDataURL(file);
		});
	}

	get_image_result(files){
		
		var promise = new Promise((resolve,reject)=>{
			var _URL = window.URL; /*|| window.webkitURL*/
			var file, img;
			var res:any = {};

			res.type = files[0].type;

			for (var i = 0, f; f = files[i]; i++) {				
				var reader = new FileReader();
				reader.onload = (function(theFile) {
				res.name = theFile.name;

				return function(e) {
					if(reader.result != null){
						res.img = reader.result;
						resolve(res);
					}
				 };
				})(f);
				reader.readAsDataURL(f);
			}
		});

		return promise;
	}

//----------------------------------------------------------------------

	get_company_ive(){
		return JSON.parse(localStorage.getItem('company_ive'));
	}

//----------------------------------------------------------------------

	drop_company_ive(){
		localStorage.removeItem('company_ive');
	}

//----------------------------------------------------------------------

	get_company(){
		var data = this.get_session();
		return data['company'];
	}

//----------------------------------------------------------------------

	set_company_ive(data){
		localStorage.setItem('company_ive',JSON.stringify(data));
	}

//----------------------------------------------------------------------

	add_commas(val){
		if(val == null || val == undefined) return (0).toFixed(2);
		while (/(\d+)(\d{3})/.test(val.toString())){
		  val = val.toString().replace(/(\d+)(\d{3})/, '$1'+','+'$2');
		}
		return val;
	}

//----------------------------------------------------------------------

	get_list_banks(){		
		var headers = this.get_auth_headers();
		var promise = new Promise((resolve, reject) => {
			this.http.get(this.api_url()+'banks',headers)
				.subscribe(data=>{
					resolve(data);
				},error=>{
					reject(false);
				});
		});

		return promise;
	}

//----------------------------------------------------------------------

	request_userdata(parameters){
		var pr = new Promise((resolve,reject)=>{
			var headers = this.get_auth_headers();
			this.http.post(this.api_url()+'get_userdata',JSON.stringify(parameters),headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	max_date(){
		var date = new Date();
		date.setDate( date.getDate() );
		date.setFullYear( date.getFullYear() - 18 ); /*Minimo 18 años de edad*/
		var diff = date.getFullYear() + "-" + ((date.getMonth() + "").length == 1 ? "0"+date.getMonth() : date.getMonth()) + '-' + (date.getDate());
		return diff;
	}

//----------------------------------------------------------------------

	get_current_date(){
		var today = new Date();
		var dd = String(today.getDate()).padStart(2, '0');
		var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
		var yyyy = today.getFullYear();
		return mm + '/' + dd + '/' + yyyy;
	}

//----------------------------------------------------------------------

	get_current_year(){
		return (new Date()).getFullYear();
	}

//----------------------------------------------------------------------

	calc(data){

		var c = 0+"";
		if(data.type == 'sell'){
			c = data.change_type != null && data.f_value_currency != null ? (data.f_value_currency / data.change_type).toFixed(2) : (0).toFixed(2);
		}else{
			c = data.change_type != null && data.f_value_currency != null ? (data.f_value_currency * data.change_type).toFixed(2) : (0).toFixed(2);
		}

		// var validate = c.split('.');
		// if(validate[1][0] == "9") c = this.add_commas(Math.round(parseFloat(c)));
		// if(validate[1][0] != "9") c = this.add_commas(parseFloat(c));

		return c;
	}

//----------------------------------------------------------------------
	
	// Get otherwise calc
	get_otherwise_calc(data:any,res:any,amount:any){
		var pr = new Promise((resolve,reject)=>{
			if(data.type == 'sell'){
				resolve(amount > 0 ? (amount * this.format_exchange_rate(res.gtx_sell)) : (0).toFixed(2));
			}else{
				resolve(amount > 0 ? (amount / this.format_exchange_rate(res.gtx_buy)) : (0).toFixed(2));
			}
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_difference(data:any){
		return Math.abs(this.format_exchange_rate(data.buy) - this.format_exchange_rate(data.sell));
	}

//----------------------------------------------------------------------

	// Calcula el ahorro
	// data => información original de la transacción
	// res => puntos de diferencia y tasas de la comparativa
	get_saving(data,res){
		if(data.f_value_currency == undefined || data.f_value_currency == null) return 0;
		var total = 0;

		if(data.type == 'buy') total = Math.abs(res.points_buy) * (data.f_value_currency);
		if(data.type == 'sell') total = Math.abs((data.l_value_currency+"").search(',') >= 0 ? (data.l_value_currency).replace(/,/g,'') * res.points_sell : data.l_value_currency * res.points_sell);
		if(isNaN(total)) return 0;

		var result = this.add_commas((total).toFixed(2));
		return result;
	}

//----------------------------------------------------------------------

	show_loading(){
		Swal.fire({
	      title: this.translate.instant('m13'),
	      text: this.translate.instant('m14'),
	      imageUrl: "/assets/spinner.gif",
	      showConfirmButton: false,
	      allowOutsideClick: false
	    });
	}

//----------------------------------------------------------------------

	format_month(month){
		switch (month) {
			case 1: return this.translate.instant('m15');			case 2: return this.translate.instant('m16');			case 3: return this.translate.instant('m17');			case 4: return this.translate.instant('m18');			case 5: return this.translate.instant('m19');			case 6: return this.translate.instant('m20');			case 7: return this.translate.instant('m21');			case 8: return this.translate.instant('m22');			case 9: return this.translate.instant('m23');			case 10: return this.translate.instant('m24');			case 11: return this.translate.instant('m25');			case 12: return this.translate.instant('m26');		}
	}

//----------------------------------------------------------------------

	hide_loading(){
		var pr = new Promise((resolve,reject)=>{
			return resolve(Swal.close());
		});
		return pr;
	}

//----------------------------------------------------------------------

	get_all_municipalities(){
		var pr = new Promise((resolve,reject)=>{
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_all_municipalities',headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_nationalities(){
		var pr = new Promise((resolve,reject)=>{
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_nacionalidad',headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_activities(){
		var pr = new Promise((resolve,reject)=>{
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_actividad',headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_departaments(){
		var pr = new Promise((resolve,reject)=>{
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_departamentos',headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	get_municipies(code){
		var pr = new Promise((resolve,reject)=>{

			if(code == null) reject(false);
			
			var headers = this.get_headers();
			this.http.get(this.api_url()+'get_municipios/'+code,headers)
				.subscribe((res)=>{
					resolve(res);
				},error=>{
					reject(false);
				});
		});

		return pr;
	}

//----------------------------------------------------------------------

	add_comission(value,rate,type){

		value = value.toString().replace(/,/g,'');

		var c:any = environment.comission;
		this.listenCommission.subscribe((res)=>{ c = res; });

		var x = 0;
		if(type == 'sell') return (parseFloat(value) + parseFloat(c)).toFixed(2);
		if(type == 'buy') return (parseFloat(value) + (parseFloat(c) / rate)).toFixed(2);
	}

//----------------------------------------------------------------------

	get_comission(){
		var c = 0;
		this.listenCommission.subscribe((res)=>{ c = res; });
		return (c != 0 ? c : environment.comission);
	}

//----------------------------------------------------------------------

	get_status(date:any){
		var cdate:any = new Date();
		date = new Date(date);

		var diff = Math.abs(cdate - date);
		// cdate.setDate(date.getDate() );
		// cdate.setFullYear(cdate.getFullYear());
		// var diff = cdate.getFullYear() + "-" + ((cdate.getMonth() + "").length == 1 ? "0"+cdate.getMonth() : cdate.getMonth()) + '-' + (cdate.getDate());
		return diff;
	}

//----------------------------------------------------------------------

	update_lang(lang){
		var pr = new Promise((resolve,reject)=>{
			var data = this.get_session();
				data['settings'].lang = lang;
				this.set_session(data);
				resolve(true);
			});

		return pr;
	}

//----------------------------------------------------------------------

	get_lang(){
		var userdata = this.get_session();
		if(!userdata) return environment.lang;

		try{
			var lang = userdata.settings.lang;		
			return (lang ? lang : environment.lang);
		}catch(e){
			return environment.lang;
		}
	}

//----------------------------------------------------------------------

	convert_to_dollar(commission,exchange_rate){
		return (parseFloat(commission) / exchange_rate).toFixed(2);
	}

//----------------------------------------------------------------------

	resolve_transfer(data,_add_comission = true){
		
		// Establecer moneda de cambio
	    data.currency = data.currency ? data.currency : data.type == 'buy' ? '$' : 'Q';

	    if(data.transaction_type == 'card' || data.transaction_type == 'third'){ /*Si es pago con tarjeta*/

	        if(data.exchange_type == 'direct'){ /*Dolar a dolar o quetzal a quetzal*/
	            
	            data.amount_settle = this.add_commas(
	                                    this.format_amount(data.amount_receive)
	                                );

	            if(_add_comission) data.amount_settle = this.add_comission(data.amount_receive,data.change_type,(data.currency == '$' ? 'buy' : data.currency == 'Q' ? 'sell' : 'buy'));

	            if(data.type == 'sell'){ // Convertir de quetzal/dolar a dolar/dolar
	            
	            	data.currency_receive = 'Q';
	            	data.currency_settle = 'Q';
	            
	            }else{ // Convertir dolar/quetzal a quetzal/quetzal

	            	data.currency_receive = '$';
	            	data.currency_settle = '$';

	            	// Dolarizar comisión
	            	data.commission = data.comission != null ? this.convert_to_dollar(data.commission,data.change_type) : '0.00';

	            }

	            // Remover parámetros innecesarios
		        data.amount_savings = '';
		        data.type = '';

	        }else{ // Indirecto

	            if(data.type == 'sell'){
	            	data.currency_settle = 'Q';
	            	data.currency_receive = '$';
	            }else{
	            	data.currency_settle = '$';
	            	data.currency_receive = 'Q';

	            	// Dolarizar comisión
	            	data.commission = this.convert_to_dollar(data.commission,data.change_type);
	            }

				// Convertir parámetros
	            data.amount_savings = this.add_commas(data.amount_savings);	    	
		    	data.type = (data.type == 'buy' ? this.translate.instant('t35') : this.translate.instant('t37'));
	        }

	        // Establecer tipo de transacción
	        data.transaction_type = this.translate.instant('t465');

	    }else{

	    	if(data.type == 'sell'){
	           	data.currency_settle = 'Q';
	           	data.currency_receive = '$';
            }else{
            	data.currency_settle = '$';
            	data.currency_receive = 'Q';

            	// Dolarizar comisión
            	data.commission = this.convert_to_dollar(data.commission,data.change_type);
            }

	    	// Establecer tipo de transacción
	    	data.transaction_type = this.translate.instant('t464');

	    	// Convertir parámetros
	    	data.amount_savings = this.add_commas(data.amount_savings);	    	
	    	data.type = (data.type == 'buy' ? this.translate.instant('t35') : this.translate.instant('t37'));
	    }

	    return data;
	}

//-----------------------------------------------------------------------

	generateClass(length) {
	    var result           = '';
	    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	    var charactersLength = characters.length;
	    for ( var i = 0; i < length; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * 
	 	  charactersLength));
	   }
	   return result;
	}

//-----------------------------------------------------------------------

	get_notice_banner(d,muted = false,autoplay = false,min = false){
		if(d.url.search('mp4') > 0){
			return '<video class="'+(min ? 'news_video' : '')+' margin block" '+(!min ? 'controls' : '')+'  '+(muted ? 'muted' : '')+' '+(autoplay ? 'autoplay' : '')+'>\
				<source type="video/mp4" src="'+d.url+'"></source>\
			</video>';
		}else{
			return '<img src="'+d.url+'" width="100%" alt="">';
		}
	}

//----------------------------------------------------------------------

	upload_document(name,file){
		return new Promise((resolve,reject)=>{

			var user_id = this.get_user_id();
	      	var headers = {
	          headers: new HttpHeaders({
	            'Authorization': 'Bearer '+this.get_token(),
	          })
	        };

	        this.get_image_result(file).then((res:any)=>{
				var f = new FormData();
				f.append(name,this.convert_to_form_data(res.img));

				this.http.post(this.api_url()+'clients/'+user_id+'/ive/upload_document',f,headers)
					.subscribe((result)=>{
						resolve(result);
					},error=>{
						reject(error);
					});
	        });

		});
	}

//----------------------------------------------------------------------

	save_banner_code(code){
		localStorage.setItem('code',JSON.stringify(code));
	}

//----------------------------------------------------------------------

	get_banner_code(){
		return JSON.parse(localStorage.getItem('code'));
	}

//----------------------------------------------------------------------

	hide_account(v){
		var pointer = (v.length - 4);
		var response = "";
		for(var x = 0; x < v.length; x++){
			response += (x < pointer ? 'x' : v[x]);
		}

		return response;
	}

//----------------------------------------------------------------------

	send_medal_event(type){
		var headers = this.get_auth_headers();
		var parameters = JSON.stringify({
			type:type,
			client_id:this.get_user_id()
		});
		this.http.post(this.api_url()+'medal_event',parameters,headers)
			.subscribe(data=>{});
	}

//----------------------------------------------------------------------

	validate_inputs(data){
		return new Promise((resolve,reject)=>{
			var res = [];
			var flag = true;
			for(var x = 0; x < data.length; x++){
				if(typeof(data[x].value) == 'undefined' || data[x].value == null){
					res.push(`El campo de ${data[x].name} es requerido`);
					flag = false;
				}
			}

			if(flag) resolve(true);
			reject(res);
		});
	}

//----------------------------------------------------------------------

	in_array(needle, haystack) {
	    var length = haystack.length;
	    for(var i = 0; i < length; i++) {
	        if(haystack[i] == needle) return true;
	    }
	    return false;
	}

//----------------------------------------------------------------------

	get_medal_filter(){
		
	}
}