import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-recommends',
  templateUrl: './recommends.component.html',
  styleUrls: ['./recommends.component.css']
})
export class RecommendsComponent implements OnInit {

	user:any;

	constructor(
		public global: GlobalService
	) { 

		this.user = this.global.get_userdata();
	}

	ngOnInit() {
	}

}
