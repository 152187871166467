import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-associations',
  templateUrl: './associations.component.html',
  styleUrls: ['./associations.component.css']
})
export class AssociationsComponent implements OnInit {


	data:any = [];
	constructor(
		private http: HttpClient,
		public global: GlobalService
	){}

	ngOnInit(){
		this.list();
	}

	list(){
		this.http.get(this.global.api_url()+'list_associations',this.global.get_headers())
			.subscribe((res)=>{
				this.data = res['data'];
			});
	}

}
