import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import '@firebase/messaging';
import { GlobalService } from '../services/global.service';

import { environment } from '../../environments/environment';

@Injectable()
export class PushNotiicationsService{
	
	messaging:any = null;

	constructor(
		private global: GlobalService
	){
		firebase.initializeApp(environment.firebase);
		if(firebase.messaging.isSupported()){
			this.messaging = firebase.messaging();
		}
	}

	// Obtener notificaciones
	watchMessages(){
		this.messaging.onMessage((n)=>{
			let audio = new Audio();
			  audio.src = "../../../assets/notify.mp3";
			  audio.load();
			  audio.play();

			window.navigator.vibrate(200);
			navigator.vibrate([200]);
			console.log(n);
			this.global.set_notification(n);
		});
	}

	// Obtener id de firebase
	getToken(){
		return this.messaging.getToken().then((token)=>{ return token; });
	}

	// Obtener suscribción
	getSubscription() : Promise<any>{
		if(!navigator || !this.messaging) return;
		return navigator.serviceWorker.getRegistrations().then(registrations=>{

			const firebaseSWs = registrations.filter(sw => { 
				return sw.active && sw.active.scriptURL.includes("firebase-messaging") 
			});

			if(firebaseSWs.length < 1) return Promise.resolve(null);
			return firebaseSWs[0].pushManager.getSubscription();
		});
	}

	// Solicitar permisos
	requestPermission() : Promise<void>{
		return this.messaging.requestPermission().then(()=>{
			return this.messaging.getToken();
		}).catch(console.log);
	}

	// Remover permisos
	cancelPermission() : Promise<void>{
		const subscriptionPr = this.getSubscription();

		return subscriptionPr.then((pushS: PushSubscription)=>{
			if(!pushS) return Promise.resolve(null);
			return pushS.unsubscribe();
		});
	}
}