import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css'],
  providers: [GlobalService]
})
export class NewsComponent implements OnInit {

	data:any;
	resolve:Promise<boolean>;

	constructor(
		public global: GlobalService,
		private http: HttpClient
	) { 

	}

	get_news(){
		var headers = this.global.get_headers();
		this.http.get(this.global.api_url()+'get_noticias',headers)
			.subscribe((data)=>{
				if(data){
					this.data = data;
					this.resolve = Promise.resolve(true);
				}
			});
	}

	ngOnInit() {
		this.get_news();
	}
}