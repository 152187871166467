import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.css']
})
export class DocumentationComponent implements OnInit {

	data:any = [];
	content:any = [];

	constructor(
		private http: HttpClient,
		private global: GlobalService
	){}

	ngOnInit() {
		this.list();
	}

	list(){
		this.http.get(this.global.api_url()+'documentation',this.global.get_headers())
			.subscribe((res)=>{
				this.data = res['data'];
				this.content = this.data[0].body[0].url;
			});
	}

	select_d(data){
		this.content = data.url;
	}

}
