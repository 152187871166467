import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liquidation',
  templateUrl: './liquidation.component.html',
  styleUrls: ['./liquidation.component.css']
})
export class LiquidationComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
