import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewAccountComponent } from './new-account/new-account.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HistoryChartComponent } from './history-chart/history-chart.component';
import { FormsModule  } from "@angular/forms"; 
import { HttpClient,HttpClientModule } from '@angular/common/http';

import { ChartsModule } from 'ng2-charts';
import { NumDirective } from './directives/num.directive';
import { UpdatedComponent } from './updated/updated.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { TranslateService } from '@ngx-translate/core';

import { AngularFireModule } from '@angular/fire';
import { environment } from '../../environments/environment';

// import { BrowserModule } from '@angular/platform-browser';

// import { NgSelect2Module } from 'select2';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
  	NewAccountComponent,
  	HistoryChartComponent,
  	NumDirective,
  	UpdatedComponent
  ],
  imports: [
    CommonModule, 
    RouterModule, 
    NgbModule, 
    ChartsModule,
    FormsModule,
    // BrowserModule,
    HttpClientModule,
    // NgSelect2Module,
    // AngularFireModule.initializeApp(environment.firebase),
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
  	NewAccountComponent, 
  	HistoryChartComponent,
  	NumDirective, 
  	UpdatedComponent,
    TranslateModule
  ],
})
export class SharedModule { }
