import { Component, OnInit, } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { Router } from "@angular/router";
import { Observable } from 'rxjs/Rx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';  

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

	data:any = {};
	email:string;
	reset_sent:boolean = false;

	constructor(
		private modalService: NgbModal,
		private http:HttpClient,
		private global:GlobalService,
		private router: Router,
		public translate: TranslateService
	){}

	ngOnInit(){}

	validate(){

		var parameters = JSON.stringify(this.data);
		var headers = this.global.get_headers();
		this.global.show_loading();

		this.http.post(this.global.api_url()+'auth/login',parameters,headers)
			.subscribe(data=>{

				this.global.hide_loading();

				if(data['success'] !== 200){
					this.global.show_alert('warning','Aviso',data['message'] || 'Ocurrió un error, por favor intente de nuevo')
					return;
				}

				this.global.set_session(data);
				window.location.href="/#/app/operations";

			},error=>{
				this.global.hide_loading();
				this.global.show_alert('error',this.translate.instant('m30'),this.translate.instant('m31'));
			});
	}

	open(content) {
	   this.modalService.open(content, {size: 'lg'})
  	}

  	send_reset(){
  		if(this.email == null) return;

  		this.global.show_loading();

  		var headers = this.global.get_headers();
  		var parameters = JSON.stringify({email:this.email});

  		this.http.post(this.global.api_url()+'reset_password_without_token',parameters,headers)
  			.subscribe(res=>{
  				this.global.hide_loading();
  				if(res['code'] == 200){
  					this.global.show_alert('success',this.translate.instant('m3'),this.translate.instant('t367'));
  					this.close();
  				}else{
  					this.global.show_alert('error','Error',(res['msg'] ? res['msg'] : this.translate.instant('m28')));
  				}
  			},error=>{
  				this.global.hide_loading();
  				this.global.show_alert('error','Error',this.translate.instant('m28'));
  			});
  	}

  	close() {
	   this.modalService.dismissAll();
	}
}