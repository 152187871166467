import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-updated',
  templateUrl: './updated.component.html',
  styleUrls: ['./updated.component.css']
})
export class UpdatedComponent implements OnInit {
  @ViewChild('content', {static: false}) content;

  constructor( private modalService: NgbModal) { }

  ngOnInit() {
  }
  open() {
    this.modalService.open(this.content,{size: 'lg'})
  }
  close() {
    this.modalService.dismissAll();
  }
}
