import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { HttpClient } from '@angular/common/http';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {

	id:any;
	data:any = {};
	render:Promise<boolean>;
	demo:any = `<iframe width=\"1280\" height=\"720\" src=\"https://www.youtube.com/embed/nK3bCTUYxEQ\" title=\"YouTube video player\" frameborder=\"0\" allow=\"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture\" allowfullscreen></iframe>`;

	constructor(
		private _route: ActivatedRoute,
		private http: HttpClient,
		public global: GlobalService,
		private sanitizer: DomSanitizer
	) { 
		this._route.paramMap.subscribe((params: ParamMap) =>  {
			this.id = params.get('id');
		});
	}

	get_notice(){
		var headers = this.global.get_headers();
		this.http.get(this.global.api_url()+'get_noticia/'+this.id,headers)
			.subscribe((data)=>{
				this.data = data;
				this.data.cuerpo = this.sanitizer.bypassSecurityTrustHtml(this.data.cuerpo);
				this.render = Promise.resolve(true);
			});
	}

	ngOnInit() {
		this.get_notice();
		this.global.send_medal_event(3); /*Asignar medalla "leíste tu primera noticia"*/
	}

}
