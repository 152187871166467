import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { DetailComponent } from './detail/detail.component';
import { NewsComponent } from './news/news.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RoleComponent } from './role/role.component';
import { PathNotFoundComponent } from './path-not-found/path-not-found.component';
import { FaqComponent } from './faq/faq.component';
import { PoliticsComponent } from './politics/politics.component';
import { RecommendsComponent } from './recommends/recommends.component';
import { TermsComponent } from './terms/terms.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { AssociationsComponent } from './associations/associations.component';

export const routes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'about', component: AboutComponent},
  {path: 'detail/:id', component: DetailComponent},
  {path: 'news', component: NewsComponent},
  {path: 'login', component: LoginComponent},
  {path: 'recommends', component: RecommendsComponent},
  {path: 'role/:id', component: RoleComponent},
  {path: 'role', component: RoleComponent},
  {path: 'recommends', component: RecommendsComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'register/:type/:id', component: RegisterComponent},
  {path: 'register/:type', component: RegisterComponent},
  {path: 'politics', component: PoliticsComponent},
  {path: 'terms', component: TermsComponent},
  {path: 'documentation', component: DocumentationComponent},
  {path: 'associations', component: AssociationsComponent},
  {
    path: 'app' , loadChildren: () => import('../operation/operation.module').then(m => m.OperationModule)
  },
  {path: '404', component: PathNotFoundComponent},
  {path: '**', redirectTo: '/404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule {}
