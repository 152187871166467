import { Component, OnInit, ViewChild, TemplateRef  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  providers: [GlobalService]
})
export class FooterComponent implements OnInit{

  isFooterShow = true;
  user:any;
  mail:any = environment.mail;
  url_cache:any;
  data_contact:any = {};
  params:any = {};

  constructor(
      private modalService: NgbModal,
      private router:Router,
      public global: GlobalService,
      private http: HttpClient,
      private translate:TranslateService
    ) {
    router.events.subscribe((eve:any) => {
     
      if(this.router.url == '/login' ||
      this.router.url == '/role' ||
      this.router.url == '/register'
      ) {
        this.isFooterShow = false;
      } else {
        this.isFooterShow = true;
      }

      if(eve.url == this.url_cache || eve.url == undefined){
        return;
      }

      this.url_cache = eve.url;
      this.user = this.global.get_userdata();
      
    });

  }

  ngOnInit(){

    this.global.get_parameters().then((res)=>{
      if(res['code'] != 200) return;
      this.global.set_broadcast_parameters(res['data']);
    });
    
    this.global.listenParameters.subscribe(params=>{
      if(!params) return;
      this.params = params;
    });
  }

  open(content) {
    this.modalService.open(content, {size: 'lg'});
  }

  close() {
    this.modalService.dismissAll();
  }

  send_contact(){
    if(this.data_contact.name == null ||
      this.data_contact.lastname == null ||
      this.data_contact.email == null ||
      this.data_contact.subject == null ||
      this.data_contact.description == null
    ){
      this.global.show_alert('warning','Error',this.translate.instant('m2'));
      return;
    }

    this.global.show_loading();
    var headers = this.global.get_headers();
    var parameters = JSON.stringify(this.data_contact);

    this.http.post(this.global.api_url()+'enviar_correo_contacto',parameters,headers)
      .subscribe(res=>{
        this.close();
        this.global.show_alert('success',this.translate.instant('m3'),this.translate.instant('m34'));
      },error=>{
        this.global.show_alert('error','Error',this.translate.instant('m28'));
      });
  }

  remove_strings(val){
    var x = val.replace(/-/g,'');
    return parseInt(x);
  }
}